var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "column content-box" }, [
      _c("section", [
        _c(
          "div",
          { staticClass: "columns meduim-12", staticStyle: { height: "0px" } },
          [
            _c(
              "div",
              { staticClass: "row info_field" },
              [
                _c(
                  "woot-button",
                  {
                    attrs: {
                      "color-scheme": "success",
                      "class-names": "button--fixed-right-top",
                      icon: "add-circle",
                      disabled: _vm.isAddItemDisabled
                    },
                    on: { click: _vm.openAddItemModal }
                  },
                  [
                    _vm._v(
                      "\n                     " +
                        _vm._s(_vm.$t("ORDER.DETAILS.ADD_ITEM_BTN")) +
                        "\n                  "
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "columns meduim-12" }, [
          _c("div", { staticClass: "row info_field" }, [
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("p", { staticClass: "columns medium-2" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.NAME")))])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "columns medium-10" },
                  [
                    _vm._v(
                      "\n                              " +
                        _vm._s(_vm.contactName) +
                        "\n                              "
                    ),
                    _vm.orderConversationLink
                      ? _c(
                          "router-link",
                          { attrs: { to: _vm.orderConversationLink } },
                          [
                            _c("woot-button", {
                              attrs: {
                                size: "small",
                                variant: "clear",
                                "color-scheme": "primary",
                                icon: "chat"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("p", { staticClass: "columns medium-2" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.PHONE")))])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "columns medium-10" }, [
                  _vm._v(
                    _vm._s(_vm.contactPhoneNumber) +
                      "\n                          "
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns meduim-12" }, [
          _c("div", { staticClass: "row info_field" }, [
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "columns medium-2" }, [
                  _c("p", { staticClass: "margin-top" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.PAID")))])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.order.paid_total,
                        expression: "order.paid_total"
                      }
                    ],
                    class: { disable_field: _vm.isOrderCancelOrComplete },
                    staticStyle: { "font-size": "14px" },
                    attrs: {
                      type: "number",
                      disabled: _vm.isOrderCancelOrComplete
                    },
                    domProps: { value: _vm.order.paid_total },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.order, "paid_total", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-6" })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row info_field" }, [
                _c("p", { staticClass: "columns medium-2 margin-bottom" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.CURRENCY")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-10" }, [
                  _c("p", [_vm._v(_vm._s(_vm.order.currency))])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns meduim-12" }, [
          _c("div", { staticClass: "row info_field" }, [
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("p", { staticClass: "columns medium-2" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.REFUNDED")))
                  ])
                ]),
                _vm._v(" "),
                _vm.order.created_at
                  ? _c("p", { staticClass: "columns medium-10" }, [
                      _vm._v(_vm._s(_vm.order.refund_amount))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row info_field" }, [
                _c("p", { staticClass: "columns medium-2 margin-bottom" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.STATUS")))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-10" }, [
                  _c(
                    "div",
                    { staticClass: "status_select_wrapper" },
                    [
                      _c("multiselect", {
                        staticClass: "small-12 font_normal",
                        attrs: {
                          options: _vm.ORDER_STATUSES,
                          "track-by": "id",
                          label: "title",
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": false,
                          "hide-selected": true,
                          disabled: _vm.isOrderCancelOrComplete,
                          placeholder: "Pick Order Status",
                          "selected-label": "",
                          "select-label": _vm.$t(
                            "FORMS.MULTISELECT.ENTER_TO_SELECT"
                          ),
                          "deselect-label": _vm.$t(
                            "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                          )
                        },
                        on: {
                          select: function($event) {
                            return _vm.updateOrderStatus($event)
                          }
                        },
                        model: {
                          value: _vm.order.order_status,
                          callback: function($$v) {
                            _vm.$set(_vm.order, "order_status", $$v)
                          },
                          expression: "order.order_status"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns meduim-12" }, [
          _c("div", { staticClass: "row info_field" }, [
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("p", { staticClass: "columns medium-2" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.ORDER_TIME")))
                  ])
                ]),
                _vm._v(" "),
                _vm.order.created_at
                  ? _c("p", { staticClass: "columns medium-10" }, [
                      _vm._v(
                        _vm._s(
                          new Date(_vm.order.created_at)
                            .toGMTString()
                            .slice(0, -7)
                        )
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns medium-6" }, [
              _c("div", { staticClass: "row info_field" }, [
                _c("p", { staticClass: "columns medium-2" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.REMARK")))])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "columns medium-10" }, [
                  _vm._v(
                    "\n                              " +
                      _vm._s(_vm.order.remark) +
                      "\n                          "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "padding-top" },
        [
          _c("h5", [_vm._v("Otder Items")]),
          _vm._v(" "),
          _c("OrderItemsTable", {
            attrs: {
              order_items: _vm.order.order_items,
              cols: _vm.cols,
              merchant: _vm.merchant,
              order: _vm.order,
              isLoading: _vm.isOrderLoading && !_vm.isUpdating,
              paymentNotify: _vm.paymentNotify,
              isOrderCancelOrComplete: _vm.isOrderCancelOrComplete,
              onRefundAmount: _vm.openRefundModal
            },
            on: {
              "on-sort-change": _vm.onSortChange,
              onQuantityChange: _vm.onQuantityChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "woot-button",
            {
              staticClass: "button--fixed-left-top",
              attrs: {
                size: "small",
                "color-scheme": "primary",
                isLoading: _vm.isUpdating,
                isDisabled: _vm.isUpdating || _vm.isOrderCancelOrComplete
              },
              on: { click: _vm.updateOrderDetails }
            },
            [_vm._v("\n              Update\n          ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showAddItemModal
        ? _c(
            "div",
            [
              _c("AddItemModal", {
                attrs: {
                  show: _vm.showAddItemModal,
                  "on-close": _vm.closeAddItemModal,
                  order: _vm.order,
                  categories: _vm.categories,
                  catalogueItems: _vm.catalogueItems
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showRefundModal
        ? _c(
            "div",
            [
              _c("RefundModal", {
                attrs: {
                  show: _vm.showRefundModal,
                  "on-close": _vm.closeRefundModal,
                  order: _vm.order,
                  onSuccess: _vm.onRefundSuccess
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }