var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box onboard_container" }, [
    _c("section", { staticClass: "row" }, [
      _c("div", { staticClass: "column content-box" }, [
        !_vm.currentUser.confirmed
          ? _c(
              "section",
              { staticClass: "business_container verify_container" },
              [
                _c("div", { staticClass: "banner_heading" }, [
                  _c("h3", { staticClass: "page-title" }, [
                    _vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.TITLE")))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("p", { staticStyle: { margin: "0" } }, [
                      _vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.MSG_PART_1")))
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("b", [
                        _vm._v(_vm._s(_vm.currentUser.user_phone_number))
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("ARRANGE.VERIFY.MSG_PART_2")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticClass: "wp_btn",
                        on: { click: _vm.handleOpenWhatsApp }
                      },
                      [_vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.WHATSAPP_BTN")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "resend_box" },
                      [
                        _c("p", { staticStyle: { margin: "0" } }, [
                          _vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.RESEND_TEXT")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "woot-button",
                          {
                            staticClass: "resend_btn",
                            attrs: {
                              classNames: _vm.isResendEnabled ? "" : "btn_mute",
                              disabled: !_vm.isResendEnabled || _vm.isReSending
                            },
                            on: { click: _vm.handleResendLink }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.isResendEnabled
                                    ? _vm.$t("ARRANGE.VERIFY.RESEND_BTN")
                                    : _vm.$t("ARRANGE.VERIFY.RESEND_BTN") +
                                        " (" +
                                        _vm.countdown +
                                        "s)"
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _c("div", [
              _vm.showOrderForm
                ? _c("section", { staticClass: "business_service" }, [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("ARRANGE.BUSINESS_TYPE.ORDER_LEVEL"))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "services" },
                      [
                        _c("woot-input", {
                          class: { error: _vm.$v.average_order.$error },
                          attrs: {
                            type: "number",
                            label: "",
                            placeholder: "500",
                            error: _vm.$v.average_order.$error
                              ? _vm.$t("ARRANGE.BUSINESS_TYPE.ORDER_ERR")
                              : ""
                          },
                          on: { blur: _vm.$v.average_order.$touch },
                          model: {
                            value: _vm.average_order,
                            callback: function($$v) {
                              _vm.average_order =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "average_order"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn_wrap" },
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: {
                              "color-scheme": "primary",
                              "is-loading": _vm.isLoading,
                              "is-disabled": _vm.isLoading
                            },
                            on: { click: _vm.onBusinessSubmit }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("ARRANGE.BUSINESS_TYPE.BTN")) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _c("section", { staticClass: "business_service" }, [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("ARRANGE.BUSINESS_SERVICES.TITLE")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "services" },
                      _vm._l(_vm.businessServices, function(item) {
                        return _c(
                          "li",
                          {
                            key: item.id,
                            staticClass: "service",
                            class:
                              _vm.active_service &&
                              _vm.active_service.id === item.id
                                ? "active_service"
                                : "",
                            on: {
                              click: function($event) {
                                return _vm.onBusinessClick(item)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "icon" }, [
                              _c(
                                "div",
                                [
                                  _c("fluent-icon", {
                                    attrs: {
                                      icon: item.icon,
                                      viewBox: item.viewBox
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("h4", [_vm._v(_vm._s(_vm.$t(item.name)))]),
                              _vm._v(" "),
                              _vm.active_service &&
                              _vm.active_service.id === item.id
                                ? _c("p", [
                                    _vm._v(_vm._s(_vm.$t(item.description)))
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn_wrap" },
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: { isDisabled: !_vm.active_service },
                            on: { click: _vm.onServiceNext }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("ARRANGE.BUSINESS_SERVICES.BTNS.NEXT")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }