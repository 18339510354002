var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          padding: "8px",
          "padding-left": "24px",
          "padding-right": "24px",
          color: "grey",
          "align-items": "center"
        }
      },
      [_c("h5", [_vm._v(_vm._s(_vm.$t("FLOW.APPNMT_CARD.HEAD")))])]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _c("woot-input", {
              class: { error: _vm.$v.content.reschedule_btn.$error },
              attrs: {
                label: _vm.$t("FLOW.APPNMT_CARD.RESCHEDULE_BTN_LABEL"),
                error: _vm.$v.content.reschedule_btn.$error
                  ? _vm.$v.content.reschedule_btn.$model.length < 1
                    ? "Title must be at least 1 character."
                    : "Title must be at most 60 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t(
                  "FLOW.APPNMT_CARD.RESCHEDULE_BTN_PLACEHOLDER"
                )
              },
              on: { blur: _vm.$v.content.reschedule_btn.$touch },
              model: {
                value: _vm.content.reschedule_btn,
                callback: function($$v) {
                  _vm.$set(_vm.content, "reschedule_btn", $$v)
                },
                expression: "content.reschedule_btn"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.content.cancel_btn.$error },
              attrs: {
                label: _vm.$t("FLOW.APPNMT_CARD.CANCEL_BTN_LABEL"),
                error: _vm.$v.content.cancel_btn.$error
                  ? _vm.$v.content.cancel_btn.$model.length < 1
                    ? "Title must be at least 1 character."
                    : "Title must be at most 60 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.APPNMT_CARD.CANCEL_BTN_PLACEHOLDER")
              },
              on: { blur: _vm.$v.content.cancel_btn.$touch },
              model: {
                value: _vm.content.cancel_btn,
                callback: function($$v) {
                  _vm.$set(_vm.content, "cancel_btn", $$v)
                },
                expression: "content.cancel_btn"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "woot-button",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.allDone($event)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("FLOW.DONE")) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                attrs: { variant: "clear" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClose($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("FLOW.CANCEL")) + "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }