<template>
  <div class="date-picker">
      <!-- :format="isTimeOnly == 'true' ? 'HH' : 'YYYY-MM-DD HH:mm:ss'" -->
    <date-picker
      :type="isTimeOnly == 'true' ? 'time' : 'datetime'"
      :format="computedFormat"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :disabled-date="disableBeforeToday"
      @change="handleChange"
    />
  </div>
</template>

<script>
import addDays from 'date-fns/addDays';
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Date,
      default: [],
    },
    disableDates: {
      type: Number,
      default: -1,
    },
    isTimeOnly: {
      type: String,
      default: 'false',
    },
    displayFormat: {
      type: String,
      default: '', // "D MMM YYYY HH:mm:ss" | "YYYY-MM-DD HH:mm:ss" | "HH"
    },
  },
  computed: {
    computedFormat() {
      if (this.displayFormat) {
        return this.displayFormat;
      }
      return this.isTimeOnly == 'true' ? 'HH' : 'YYYY-MM-DD HH:mm:ss';
    },
  },

  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    disableBeforeToday(date) {
      const yesterdayDate = addDays(new Date(), this.disableDates);
      return date < yesterdayDate;
    },
  },
};
</script>

