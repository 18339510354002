var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          padding: "8px",
          "padding-left": "24px",
          "padding-right": "24px",
          color: "grey",
          "align-items": "center"
        }
      },
      [_c("h5", [_vm._v(_vm._s(_vm.$t("FLOW.OUTSTANDING_APNMT.HEAD")))])]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _c("woot-input", {
              class: { error: _vm.$v.list.title.$error },
              attrs: {
                label: _vm.$t("FLOW.OUTSTANDING_APNMT.TITLE"),
                error: _vm.$v.list.title.$error
                  ? _vm.$v.list.title.$model.length < 1
                    ? "Title must be at least 1 character."
                    : "Title must be at most 60 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.TITLE")
              },
              on: { blur: _vm.$v.list.title.$touch },
              model: {
                value: _vm.list.title,
                callback: function($$v) {
                  _vm.$set(_vm.list, "title", $$v)
                },
                expression: "list.title"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.list.body.$error },
              attrs: {
                label: _vm.$t("FLOW.OUTSTANDING_APNMT.BODY"),
                error: _vm.$v.list.body.$error
                  ? _vm.$v.list.body.$model.length < 1
                    ? "Body must be at least 1 character."
                    : "Body must be at most 4096 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.BODY")
              },
              on: { blur: _vm.$v.list.body.$touch },
              model: {
                value: _vm.list.body,
                callback: function($$v) {
                  _vm.$set(_vm.list, "body", $$v)
                },
                expression: "list.body"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.list.footer.$error },
              attrs: {
                label: _vm.$t("FLOW.OUTSTANDING_APNMT.FOOTER"),
                error: _vm.$v.list.footer.$error
                  ? _vm.$v.list.footer.$model.length > 60
                    ? "Footer must be at most 60 characters."
                    : ""
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.FOOTER")
              },
              on: { blur: _vm.$v.list.footer.$touch },
              model: {
                value: _vm.list.footer,
                callback: function($$v) {
                  _vm.$set(_vm.list, "footer", $$v)
                },
                expression: "list.footer"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.list.button.$error },
              attrs: {
                label: _vm.$t("FLOW.OUTSTANDING_APNMT.BTN_LEVEL"),
                error: _vm.$v.list.button.$error
                  ? _vm.$v.list.button.$model.length < 1
                    ? "Button label must be at least 1 character."
                    : "Button label must be at most 20 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.BTN_LEVEL")
              },
              on: { blur: _vm.$v.list.button.$touch },
              model: {
                value: _vm.list.button,
                callback: function($$v) {
                  _vm.$set(_vm.list, "button", $$v)
                },
                expression: "list.button"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.list.section_title.$error },
              attrs: {
                label: _vm.$t("FLOW.OUTSTANDING_APNMT.SECTION_LEVEL"),
                error: _vm.$v.list.section_title.$error
                  ? "Section title must be between 1 and 24 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.SECTION_TITLE")
              },
              on: { blur: _vm.$v.list.section_title.$touch },
              model: {
                value: _vm.list.section_title,
                callback: function($$v) {
                  _vm.$set(_vm.list, "section_title", $$v)
                },
                expression: "list.section_title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "woot-button",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.allDone($event)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("FLOW.DONE")) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                attrs: { variant: "clear" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClose($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("FLOW.CANCEL")) + "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }