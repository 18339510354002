var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "div",
        [
          _c(
            "modal",
            {
              attrs: {
                show: _vm.isOpen,
                "on-close": _vm.closeModal,
                modalMainContainerClass: "botExpressModal",
                closeBtnClass: "modal_close_btn_left",
                closeIcon: "chevron-left"
              },
              on: {
                "update:show": function($event) {
                  _vm.isOpen = $event
                }
              }
            },
            [
              _c("div", { staticClass: "botExpressModal_container" }, [
                _c("div", { staticClass: "header" }, [
                  _c("h2", { staticClass: "header_title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TITLE"))
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "step_range" }, [
                  _c("input", {
                    attrs: {
                      id: "expressBot_step_range",
                      type: "range",
                      name: "volume",
                      min: 0,
                      max: _vm.totalSteps,
                      disabled: ""
                    },
                    domProps: { value: _vm.currentStepIndex }
                  })
                ]),
                _vm._v(" "),
                !_vm.isFinalStepQrModalOpen
                  ? _c("div", [
                      _c("section", { staticClass: "expressBot" }, [
                        _c(
                          "aside",
                          [
                            _c("h2", { staticClass: "main_title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.labels[_vm.current_step].formTitle)
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "main_description" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    _vm.labels[_vm.current_step].formDescription
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.currentForm.contents, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  item.type === "card"
                                    ? [
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].title
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.title,
                                                  expression: "item.title"
                                                }
                                              ],
                                              attrs: { type: "text" },
                                              domProps: { value: item.title },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "title",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].subtitle
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.subtitle,
                                                  expression: "item.subtitle"
                                                }
                                              ],
                                              attrs: {
                                                name: "",
                                                id: "",
                                                cols: "20",
                                                rows: "6"
                                              },
                                              domProps: {
                                                value: item.subtitle
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "subtitle",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.extraConfig &&
                                        "isMentionOperatingHour" in
                                          item.extraConfig
                                          ? _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form_label check_label"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        item.extraConfig
                                                          .isMentionOperatingHour,
                                                      expression:
                                                        "item.extraConfig.isMentionOperatingHour"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    name: "",
                                                    id: ""
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      item.extraConfig
                                                        .isMentionOperatingHour
                                                    )
                                                      ? _vm._i(
                                                          item.extraConfig
                                                            .isMentionOperatingHour,
                                                          null
                                                        ) > -1
                                                      : item.extraConfig
                                                          .isMentionOperatingHour
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          item.extraConfig
                                                            .isMentionOperatingHour,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              item.extraConfig,
                                                              "isMentionOperatingHour",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              item.extraConfig,
                                                              "isMentionOperatingHour",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          item.extraConfig,
                                                          "isMentionOperatingHour",
                                                          $$c
                                                        )
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm.labels[
                                                          _vm.current_step
                                                        ].extraConfig
                                                          .isMentionOperatingHour
                                                      )
                                                    ) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.labels[_vm.current_step].contents[
                                            index
                                          ].actionButtons,
                                          function(actionBtn, btnIndex) {
                                            return [
                                              actionBtn
                                                ? _c(
                                                    "div",
                                                    {
                                                      key:
                                                        "actionBtn" + btnIndex
                                                    },
                                                    [
                                                      actionBtn.button
                                                        ? _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form_label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      actionBtn.button
                                                                    )
                                                                  ) +
                                                                  "\n                                            "
                                                              ),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item
                                                                        .actionButtons[
                                                                        btnIndex
                                                                      ].title,
                                                                    expression:
                                                                      "item.actionButtons[btnIndex].title"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type: "text",
                                                                  name: "",
                                                                  id: ""
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    item
                                                                      .actionButtons[
                                                                      btnIndex
                                                                    ].title
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      item
                                                                        .actionButtons[
                                                                        btnIndex
                                                                      ],
                                                                      "title",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      actionBtn.checkbox
                                                        ? _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form_label check_label"
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item
                                                                        .actionButtons[
                                                                        btnIndex
                                                                      ]
                                                                        .visibility,
                                                                    expression:
                                                                      "item.actionButtons[btnIndex].visibility"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  name: "",
                                                                  id: ""
                                                                },
                                                                domProps: {
                                                                  checked: Array.isArray(
                                                                    item
                                                                      .actionButtons[
                                                                      btnIndex
                                                                    ].visibility
                                                                  )
                                                                    ? _vm._i(
                                                                        item
                                                                          .actionButtons[
                                                                          btnIndex
                                                                        ]
                                                                          .visibility,
                                                                        null
                                                                      ) > -1
                                                                    : item
                                                                        .actionButtons[
                                                                        btnIndex
                                                                      ]
                                                                        .visibility
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        item
                                                                          .actionButtons[
                                                                          btnIndex
                                                                        ]
                                                                          .visibility,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c = $$el.checked
                                                                        ? true
                                                                        : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v = null,
                                                                        $$i = _vm._i(
                                                                          $$a,
                                                                          $$v
                                                                        )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            item
                                                                              .actionButtons[
                                                                              btnIndex
                                                                            ],
                                                                            "visibility",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            item
                                                                              .actionButtons[
                                                                              btnIndex
                                                                            ],
                                                                            "visibility",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        item
                                                                          .actionButtons[
                                                                          btnIndex
                                                                        ],
                                                                        "visibility",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      actionBtn.checkbox
                                                                    )
                                                                  ) +
                                                                  "\n                                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === "category_list"
                                    ? [
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].title
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.title,
                                                  expression: "item.title"
                                                }
                                              ],
                                              attrs: { type: "text" },
                                              domProps: { value: item.title },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "title",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].body
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.body,
                                                  expression: "item.body"
                                                }
                                              ],
                                              attrs: {
                                                name: "",
                                                id: "",
                                                cols: "20",
                                                rows: "6"
                                              },
                                              domProps: { value: item.body },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "body",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].footer
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.footer,
                                                  expression: "item.footer"
                                                }
                                              ],
                                              attrs: { type: "text" },
                                              domProps: { value: item.footer },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "footer",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].button
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.button,
                                                  expression: "item.button"
                                                }
                                              ],
                                              attrs: { type: "text" },
                                              domProps: { value: item.button },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "button",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.sub_type === "calendar_list"
                                          ? void 0
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.sub_type === "appointment_list"
                                          ? void 0
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.sub_type ===
                                        "outstanding_appointment"
                                          ? void 0
                                          : _vm._l(item.sections, function(
                                              section,
                                              sectionIdx
                                            ) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "actionBtn" + sectionIdx
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form_label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                _vm.labels[
                                                                  _vm
                                                                    .current_step
                                                                ].contents[
                                                                  index
                                                                ].sections[
                                                                  sectionIdx
                                                                ].title
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                section.title,
                                                              expression:
                                                                "section.title"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          domProps: {
                                                            value: section.title
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                section,
                                                                "title",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("multiselect", {
                                                      staticClass:
                                                        "column small-12 express_bot_multiselect",
                                                      attrs: {
                                                        options:
                                                          _vm.categoryOptions,
                                                        "track-by": "id",
                                                        label: "title",
                                                        multiple: true,
                                                        "close-on-select": false,
                                                        "clear-on-select": false,
                                                        "hide-selected": true,
                                                        placeholder: _vm.$t(
                                                          "CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER"
                                                        ),
                                                        "selected-label": "",
                                                        "select-label": _vm.$t(
                                                          "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                                        ),
                                                        "deselect-label": _vm.$t(
                                                          "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                                                        )
                                                      },
                                                      model: {
                                                        value: section.rows,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            section,
                                                            "rows",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "section.rows"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            })
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === "text"
                                    ? [
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].value
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            item.inputType === "textarea"
                                              ? _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.value,
                                                      expression: "item.value"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: "",
                                                    id: "",
                                                    cols: "20",
                                                    rows: "6"
                                                  },
                                                  domProps: {
                                                    value: item.value
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.value,
                                                      expression: "item.value"
                                                    }
                                                  ],
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: item.value
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.hasExtraConfig(
                                          item,
                                          "foreignContents"
                                        )
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  item.extraConfig
                                                    .foreignContents,
                                                  function(extraItem, exIdx) {
                                                    return [
                                                      extraItem.contentType ===
                                                      "card"
                                                        ? _c(
                                                            "label",
                                                            {
                                                              key: "ex" + exIdx,
                                                              staticClass:
                                                                "form_label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      _vm
                                                                        .labels[
                                                                        _vm
                                                                          .current_step
                                                                      ]
                                                                        .contents[
                                                                        index
                                                                      ]
                                                                        .foreignContents[
                                                                        exIdx
                                                                      ].title
                                                                    )
                                                                  ) +
                                                                  "\n                                            "
                                                              ),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .form_nodes[
                                                                        extraItem
                                                                          .step
                                                                      ]
                                                                        .contents[
                                                                        extraItem
                                                                          .contentIdx
                                                                      ]
                                                                        .actionButtons[
                                                                        extraItem
                                                                          .btnIndex
                                                                      ].title,
                                                                    expression:
                                                                      "form_nodes[extraItem.step].contents[extraItem.contentIdx].actionButtons[extraItem.btnIndex].title"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type: "text",
                                                                  name: "",
                                                                  id: ""
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .form_nodes[
                                                                      extraItem
                                                                        .step
                                                                    ].contents[
                                                                      extraItem
                                                                        .contentIdx
                                                                    ]
                                                                      .actionButtons[
                                                                      extraItem
                                                                        .btnIndex
                                                                    ].title
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm
                                                                        .form_nodes[
                                                                        extraItem
                                                                          .step
                                                                      ]
                                                                        .contents[
                                                                        extraItem
                                                                          .contentIdx
                                                                      ]
                                                                        .actionButtons[
                                                                        extraItem
                                                                          .btnIndex
                                                                      ],
                                                                      "title",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === "image"
                                    ? [
                                        _c(
                                          "label",
                                          { staticClass: "form_label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.labels[_vm.current_step]
                                                      .contents[index].title
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            item.inputType === "textarea"
                                              ? _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.title,
                                                      expression: "item.title"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: "",
                                                    id: "",
                                                    cols: "20",
                                                    rows: "6"
                                                  },
                                                  domProps: {
                                                    value: item.title
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "title",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.title,
                                                      expression: "item.title"
                                                    }
                                                  ],
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: item.title
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "title",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "aside",
                          [
                            _c("ExpressPreview", {
                              attrs: { currentForm: _vm.formattedCurrentForm }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("footer", { staticClass: "row submit_btns" }, [
                        _c(
                          "aside",
                          { staticClass: "medium-6 columns" },
                          [
                            _vm.isLastLabel
                              ? _c(
                                  "div",
                                  [
                                    _c("label", { staticClass: "form_label" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "FLOW.EXPRESS_BOT.CREATE_MODAL.LABELS.BOT_INBOX"
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("multiselect", {
                                      staticClass:
                                        "column small-12 express_bot_multiselect",
                                      attrs: {
                                        options: _vm.whatsAppInboxList,
                                        "track-by": "id",
                                        label: "name",
                                        multiple: false,
                                        "close-on-select": false,
                                        "clear-on-select": false,
                                        "hide-selected": true,
                                        placeholder: _vm.$t(
                                          "MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER"
                                        ),
                                        "selected-label": "",
                                        "select-label": _vm.$t(
                                          "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                        ),
                                        "deselect-label": _vm.$t(
                                          "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                                        )
                                      },
                                      on: {
                                        select: function($event) {
                                          return _vm.onSelectInbox($event)
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedInbox,
                                        callback: function($$v) {
                                          _vm.selectedInbox = $$v
                                        },
                                        expression: "selectedInbox"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isLastLabel
                              ? _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      "is-loading": _vm.isSubmitting
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onNextHandler(false)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.NEXT"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isLastLabel
                              ? _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      "is-loading": _vm.isSubmitting
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onNextHandler(true)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.SUBMIT"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPreviousAvailable
                              ? _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      variant: "clear"
                                    },
                                    on: { click: _vm.onPreviousHandler }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.PREVIOUS"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      variant: "clear"
                                    },
                                    on: { click: _vm.closeModal }
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CANCEL"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isFinalStepQrModalOpen
                  ? _c("div", [
                      _c(
                        "div",
                        [
                          _c("QRCode", {
                            attrs: { shop_url: _vm.whatsappUrl, isOnlyQR: true }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "complete_step" }, [
                        _c("div", { staticClass: " header info" }, [
                          _c("h2", { staticClass: "header_title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_TITLE"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "description" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_DESCRIPTION"
                                )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "btns" },
                          [
                            _c(
                              "woot-button",
                              {
                                attrs: { "class-names": "tmp_submit" },
                                on: { click: _vm.onTryChatbotClick }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.TRY"
                                      )
                                    ) +
                                    "\n                        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "woot-button",
                              {
                                attrs: {
                                  "class-names": "tmp_submit",
                                  variant: "clear"
                                },
                                on: { click: _vm.closeModal }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CLOSE"
                                      )
                                    ) +
                                    "\n                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }