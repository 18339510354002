<template>
  <div>
    <div class="add_campaign_container">
        <div class="campaign_heading">
            <h2>{{ $t('CAMPAIGN.ADD_STEP.TITLE') }}</h2>
            <woot-button class="" icon='dismiss' variant="clear" colorScheme="secondary" />
        </div>
        <div class="step_range_continer">
            <p>
                {{step}}
                {{ $t('CAMPAIGN.ADD_STEP.RANGE_BAR.OF') }}
                {{max_steps}}
                {{ $t('CAMPAIGN.ADD_STEP.RANGE_BAR.SELECT_TEXT') }}
            </p>
            <RangeInput :disabled="true" :min="0" :max="max_steps" :value="step" :id="'range_step'" />
        </div>
        <div v-if="step == 1">
            <ul class="campaign_types">
                <li v-for="item in types" class="campaign_type" :class="campaign_type===item.value ? 'active_type':''" :key="item.title" @click="selectCampaignType(item)">
                    <div class="type_icon">
                        <div v-html="item.icon"></div>
                    </div>
                    <div class="type_body">
                        <h3 class="name">{{item.title}}</h3>
                        <p class="description">{{item.description}}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="step === 2" class="step_container">
            <section>
                <!-- Audience filter logic -->
                <article>
                    <section>
                        <label
                            class="field_label"
                            :class="{ error: $v.appliedFilters.$error }"
                        >{{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}</label>
                        <div class="medium-12 columns audience_filter">
                            <filter-input-box
                            v-for="(filter, i) in appliedFilters"
                            :key="i"
                            v-model="appliedFilters[i]"
                            :filter-groups="filterGroups"
                            :grouped-filters="true"
                            :input-type="getInputType(appliedFilters[i].attribute_key)"
                            :operators="getOperators(appliedFilters[i].attribute_key)"
                            :dropdown-values="getDropdownValues(appliedFilters[i].attribute_key)"
                            :show-query-operator="i !== appliedFilters.length - 1"
                            :show-user-input="showUserInput(appliedFilters[i].filter_operator)"
                            :v="$v.appliedFilters.$each[i]"
                            reOrderLabels = "false"
                            @resetFilter="resetFilter(i, appliedFilters[i])"
                            @removeFilter="removeFilter(i)"
                            />
                            <div class="filter-actions mt-4">
                            <woot-button
                                icon="add"
                                class="add_campaign_filter"
                                color-scheme="primary"
                                variant="clear"
                                size="small"
                                @click="appendNewFilter"
                            >{{ $t('CONTACTS_FILTER.ADD_NEW_FILTER') }}</woot-button>
                            </div>
                        </div>
                    </section>
                </article>

                <!-- Schedule time date selector -->
                <article>
                    <label class="field_label">
                        {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
                    </label>
                    <woot-date-time-picker
                        :value="scheduledAt"
                        style="margin-top:'8px'"
                        :disable-dates="0"
                        :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
                        displayFormat="D MMM YYYY HH:mm:ss"
                        @change="onChange"
                    />
                </article>
            </section>
        </div>
        <div v-if="step === 3">
            step 3
        </div>
        <!-- <div v-else-if="campaign_type === 'running'">
            <section>Running steps</section>
        </div> -->
        <div class="campaign_btns">
            <woot-button
            class="campaign_btn"
            :isDisabled="isDisableNextBtn"
            @click="handleNext"
            >
            {{ $t('CAMPAIGN.ADD_STEP.BTNS.NEXT') }}
            </woot-button>
            <woot-button
            class="campaign_btn"
            :isDisabled="isDisableBackBtn"
            variant="clear"
            @click="handleBack"
            >
            {{ $t('CAMPAIGN.ADD_STEP.BTNS.BACK') }}
            </woot-button>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url, minLength, requiredIf } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import filterMixin from 'shared/mixins/filterMixin';
import { mixin as clickaway } from 'vue-clickaway';
import RangeInput from "../../../../components/ui/RangeInput.vue"
import {svgIcons} from "../../../../../shared/components/FluentIcon/svg_icons"
import FilterInputBox from '../../../../components/widgets/FilterInput/Index.vue';
import contactFilterItems from '../../contacts/contactFilterItems';
import countries from '../../../../../shared/constants/countries';
import { filterAttributeGroups } from '../../contacts/contactFilterItems';
import * as OPERATORS from '../../../../components/widgets/FilterInput/FilterOperatorTypes';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';

/*
    type=ONE_OFF & step 1: select type
    type=ONE_OFF & step 2: select audience & timing
    type=ONE_OFF & step 3: set message
    ----------------------------------
    type=REPEATING & step 1: select type
    type=REPEATING & step 2: select audience & timing
    type=REPEATING & step 3: set message
*/
const CAMP_TYPES = {
    ONE_OFF: "one_off",
    REPEATING: "running",
    max_step:{
        one_off: 3,
        repeating: 3,
    }
}
// const initialFilterTypes = ;
export default {
    components:{
        FilterInputBox,
        RangeInput,
        WootDateTimePicker,
    },
    mixins: [alertMixin, campaignMixin, filterMixin, clickaway],
    data(){
        return {
            campaign_type: "",
            step: 1,
            types: [
                // make a separate file for custom svgs and render as v-html
                {
                    value: CAMP_TYPES.ONE_OFF,
                    title: this.$t('CAMPAIGN.ADD_STEP.ONE_OFF.TYPE_NAME'),
                    description: this.$t('CAMPAIGN.ADD_STEP.ONE_OFF.DESCRIPTION'),
                    icon: svgIcons.oneOff({height:60,width:60}),
                },
                {
                    value: CAMP_TYPES.REPEATING,
                    title: this.$t('CAMPAIGN.ADD_STEP.REPEAT.TYPE_NAME'),
                    description: this.$t('CAMPAIGN.ADD_STEP.ONE_OFF.DESCRIPTION'),
                    icon: svgIcons.repeat({height:60,width:60}),
                },
            ],
            appliedFilters: [],
            filterGroups: [],
            filterTypes: contactFilterItems.map(filter => ({
                ...filter,
                attributeName: this.$t(`CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`),
            })),
            filterAttributeGroups,
            attributeModel: 'contact_attribute',
            filtersFori18n: 'CONTACTS_FILTER',
            scheduledAt: null,
        }
    },
    computed:{
        ...mapGetters({
            // currentUser: 'getCurrentUser',
            // uiFlags: 'campaigns/getUIFlags',
            // audienceList: 'labels/getLabels',
            getAppliedContactFilters: 'contacts/getAppliedContactFilters',
            contactStatus: 'accounts/getContactStatus',
        }),
        max_steps(){
            if (this.campaign_type === CAMP_TYPES.ONE_OFF) {
                return CAMP_TYPES.max_step.one_off
            }
            if (this.campaign_type === CAMP_TYPES.REPEATING) {
                return CAMP_TYPES.max_step.repeating
            }
            return 0
        },
        isDisableNextBtn(){
            if (!this.campaign_type) {
                return true;
            }
            // last step 3 for oneOff type
            if (this.campaign_type === CAMP_TYPES.ONE_OFF && this.step === CAMP_TYPES.max_step.one_off) {
                return true
            }
            // last step 3 for REPEATING type
            if (this.campaign_type === CAMP_TYPES.REPEATING && this.step === CAMP_TYPES.max_step.repeating) {
                return true
            }
            return false;
        },
        isDisableBackBtn(){
            if (!this.campaign_type || this.step === 1) {
                return true;
            }
            return false;
        },
    },
    async mounted(){
        // set all the intitial values
        this.setInitialStates();
        // this.$store.dispatch('attributes/get');
        this.setFilterAttributes();
        // sent contact filters
        if (this.getAppliedContactFilters.length) {
            this.appliedFilters = [...this.getAppliedContactFilters];
        } else {
            this.appliedFilters.push({
                attribute_key: 'name',
                filter_operator: 'equal_to',
                values: '',
                query_operator: 'and',
                attribute_model: 'standard',
            });
        }
    },
    watch:{
        // getAppliedContactFilters(newData){
        //     console.log({newData});
        //     if (newData.length) {
        //         this.setFilterAttributes();
        //     }
        // },
    },
    validations(){
        const onOffValidations = {
            appliedFilters: {
                required,
                $each: {
                    values: {
                        required: requiredIf(prop=>{
                            return !(prop.filter_operator === 'is_present' || prop.filter_operator === 'is_not_present');
                        }),
                    },
                },
            }
        };

        const onRepetingValidations = {};
        if (this.campaign_type === CAMP_TYPES.ONE_OFF) {
            return onOffValidations;
        }
        if (this.campaign_type === CAMP_TYPES.REPEATING) {
            return onRepetingValidations;
        }
    },
    methods:{
        async setInitialStates (){
            let allCustomAttributes = this.$store.getters['attributes/getAttributesByModel'](this.attributeModel);
            console.log({allCustomAttributes,},123);
            if (allCustomAttributes.length) {
                await this.$store.dispatch('attributes/get');
            }
            await this.$nextTick()
            this.setFilterAttributes();
            console.log("See end");
        },
        selectCampaignType(typeEl){
            this.campaign_type = typeEl.value
        },
        handleNext(){
            console.log({filterGroups:this.filterGroups});
            // if not type, go set type and show step 0
            if (this.campaign_type === CAMP_TYPES.ONE_OFF) {
                switch (this.step) {
                    case 1:
                        this.step += 1
                        break;
                    case 2:
                        this.step += 1
                        break;

                    default:
                        break;
                }
            }else  if (this.campaign_type === CAMP_TYPES.REPEATING) {
                // handle form running or repeating
            }
        },
        handleBack(){
            this.step -= 1;
        },
        getInputType(key) {
            const type = this.filterTypes.find(filter => filter.attributeKey === key);
            return type.inputType;
        },
        getOperators(key) {
            const type = this.filterTypes.find(filter => filter.attributeKey === key);
            return type.filterOperators;
        },
        getDropdownValues(type) {
            switch (type) {
                case 'country_code':
                return countries;
                case 'status_id':
                return this.contactStatus;
                default:
                return undefined;
            }
        },
        showUserInput(operatorType) {
            return !(operatorType === 'is_present' || operatorType === 'is_not_present');
        },
        resetFilter(index, currentFilter) {
            this.appliedFilters[index].filter_operator = this.filterTypes.find(
                filter => filter.attributeKey === currentFilter.attribute_key
            ).filterOperators[0].value;
            this.appliedFilters[index].values = '';
        },
        removeFilter(index) {
            if (this.appliedFilters.length <= 1) {
                this.showAlert(this.$t('CONTACTS_FILTER.FILTER_DELETE_ERROR'));
            } else {
                this.appliedFilters.splice(index, 1);
            }
        },
        appendNewFilter() {
            this.appliedFilters.push({
                attribute_key: 'name',
                filter_operator: 'equal_to',
                values: '',
                query_operator: 'and',
            });
        },
        customAttributeInputType(key) {
            switch (key) {
                case 'date':
                return 'date';
                default:
                return 'plain_text';
            }
        },
        getOperatorTypes(key) {
            switch (key) {
                case 'list':
                    return OPERATORS.OPERATOR_TYPES_1;
                case 'text':
                    return OPERATORS.OPERATOR_TYPES_3;
                case 'number':
                    return OPERATORS.OPERATOR_TYPES_1;
                case 'link':
                    return OPERATORS.OPERATOR_TYPES_1;
                case 'date':
                    return OPERATORS.OPERATOR_TYPES_4;
                case 'checkbox':
                    return OPERATORS.OPERATOR_TYPES_1;
                default:
                    return OPERATORS.OPERATOR_TYPES_1;
            }
        },
        onChange(value) {
            this.scheduledAt = value;
        },
        // end methods
    },
}
</script>

<style lang="scss" scoped>
.common_font {
    font-family: 'Figtree', sans-serif;
    line-height: 1.4;
    color: #1F1F1F;
}
.add_campaign_container{
    max-width: 640px;
    margin: auto;
    margin-top: 32px;
}
.campaign_heading{
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2{
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
        font-family: 'Figtree';
        color: #1F1F1F;
    }
}

.step_range_continer{
    margin-top: 16px;
    margin-bottom: 32px;
    p{
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        font-family: 'Figtree';
        color: #1F1F1F;
    }
}
.step_container{
    max-width: 90%;
    @media (max-width: 768px) { // Mobile
        max-width: 100%;
    }
}
.campaign_btns{
    display: flex;
    gap: 16px;
    margin-top: 32px;
    .campaign_btn{
        width: 280px;
    }
}
.campaign_types{
    display: flex;
    gap: 12px;
    list-style: none;
    margin-top: 24px;
}
.campaign_type{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 280px;
    height: 240px;
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    padding: 20px;
    margin: 24px 0 0 0;
    cursor: pointer;
    transition:  all ease-in-out 0.2s;

    .type_body{
        margin-top: 16px;
        text-align: center;
        .name{
            @extend .common_font;
            font-weight: 600;
            font-size: 16px;
            color: #000;
        }
        .description{
            @extend .common_font;
            font-weight: 500;
            font-size: 14px;
            color:#858585;
            margin-top: 4px;
        }
    }
}
.active_type{
    border: 2px solid #8D25F6;
    transition:  all ease-in-out 0.2s;
}
.field_label{
    @extend .common_font;
    font-weight: 500;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 8px;
}
.audience_filter{
    margin-bottom: 8px;
}
</style>


<style>
/*  */
.audience_filter .filters .filter{
    padding: 0;
    border: none;
    background-color: #fff;
}
.audience_filter .filters .filter .filter-inputs .filter__question,
.audience_filter .filters .filter .filter-inputs .filter__operator{
    margin-right: 12px;
}


.add_campaign_filter svg,
.add_campaign_filter .button__content{
    font-family: 'Figtree', sans-serif;
    line-height: 1.4;
    font-weight: 600;
    font-size: 14px;
    color: #8D25F6;
}
.add_campaign_filter:hover .button__content{
    color: inherit;
}
</style>
