var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "add_campaign_container" }, [
      _c(
        "div",
        { staticClass: "campaign_heading" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD_STEP.TITLE")))]),
          _vm._v(" "),
          _c("woot-button", {
            attrs: {
              icon: "dismiss",
              variant: "clear",
              colorScheme: "secondary"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "step_range_continer" },
        [
          _c("p", [
            _vm._v(
              "\n              " +
                _vm._s(_vm.step) +
                "\n              " +
                _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.RANGE_BAR.OF")) +
                "\n              " +
                _vm._s(_vm.max_steps) +
                "\n              " +
                _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.RANGE_BAR.SELECT_TEXT")) +
                "\n          "
            )
          ]),
          _vm._v(" "),
          _c("RangeInput", {
            attrs: {
              disabled: true,
              min: 0,
              max: _vm.max_steps,
              value: _vm.step,
              id: "range_step"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.step == 1
        ? _c("div", [
            _c(
              "ul",
              { staticClass: "campaign_types" },
              _vm._l(_vm.types, function(item) {
                return _c(
                  "li",
                  {
                    key: item.title,
                    staticClass: "campaign_type",
                    class:
                      _vm.campaign_type === item.value ? "active_type" : "",
                    on: {
                      click: function($event) {
                        return _vm.selectCampaignType(item)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "type_icon" }, [
                      _c("div", { domProps: { innerHTML: _vm._s(item.icon) } })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "type_body" }, [
                      _c("h3", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "description" }, [
                        _vm._v(_vm._s(item.description))
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 2
        ? _c("div", { staticClass: "step_container" }, [
            _c("section", [
              _c("article", [
                _c("section", [
                  _c(
                    "label",
                    {
                      staticClass: "field_label",
                      class: { error: _vm.$v.appliedFilters.$error }
                    },
                    [_vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.FORM.AUDIENCE.LABEL")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "medium-12 columns audience_filter" },
                    [
                      _vm._l(_vm.appliedFilters, function(filter, i) {
                        return _c("filter-input-box", {
                          key: i,
                          attrs: {
                            "filter-groups": _vm.filterGroups,
                            "grouped-filters": true,
                            "input-type": _vm.getInputType(
                              _vm.appliedFilters[i].attribute_key
                            ),
                            operators: _vm.getOperators(
                              _vm.appliedFilters[i].attribute_key
                            ),
                            "dropdown-values": _vm.getDropdownValues(
                              _vm.appliedFilters[i].attribute_key
                            ),
                            "show-query-operator":
                              i !== _vm.appliedFilters.length - 1,
                            "show-user-input": _vm.showUserInput(
                              _vm.appliedFilters[i].filter_operator
                            ),
                            v: _vm.$v.appliedFilters.$each[i],
                            reOrderLabels: "false"
                          },
                          on: {
                            resetFilter: function($event) {
                              return _vm.resetFilter(i, _vm.appliedFilters[i])
                            },
                            removeFilter: function($event) {
                              return _vm.removeFilter(i)
                            }
                          },
                          model: {
                            value: _vm.appliedFilters[i],
                            callback: function($$v) {
                              _vm.$set(_vm.appliedFilters, i, $$v)
                            },
                            expression: "appliedFilters[i]"
                          }
                        })
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "filter-actions mt-4" },
                        [
                          _c(
                            "woot-button",
                            {
                              staticClass: "add_campaign_filter",
                              attrs: {
                                icon: "add",
                                "color-scheme": "primary",
                                variant: "clear",
                                size: "small"
                              },
                              on: { click: _vm.appendNewFilter }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("CONTACTS_FILTER.ADD_NEW_FILTER"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "article",
                [
                  _c("label", { staticClass: "field_label" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL")) +
                        "\n                  "
                    )
                  ]),
                  _vm._v(" "),
                  _c("woot-date-time-picker", {
                    staticStyle: { "margin-top": "'8px'" },
                    attrs: {
                      value: _vm.scheduledAt,
                      "disable-dates": 0,
                      "confirm-text": _vm.$t(
                        "CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM"
                      ),
                      placeholder: _vm.$t(
                        "CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER"
                      ),
                      displayFormat: "D MMM YYYY HH:mm:ss"
                    },
                    on: { change: _vm.onChange }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 3
        ? _c("div", [_vm._v("\n          step 3\n      ")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "campaign_btns" },
        [
          _c(
            "woot-button",
            {
              staticClass: "campaign_btn",
              attrs: { isDisabled: _vm.isDisableNextBtn },
              on: { click: _vm.handleNext }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.BTNS.NEXT")) +
                  "\n          "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              staticClass: "campaign_btn",
              attrs: { isDisabled: _vm.isDisableBackBtn, variant: "clear" },
              on: { click: _vm.handleBack }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.BTNS.BACK")) +
                  "\n          "
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }