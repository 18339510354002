import Index from './Index';
import SettingsContent from '../Wrapper';
import ExpressBots from './express_bots/ExpressBots.vue';
import { frontendURL } from '../../../../helper/URLHelper';
import FlowList from '../bot/flow/ListView';
import FlowEdit from '../bot/flow/Editor';
import AddTemplate from './EmailTemplates/AddTemplate';
import EditTemplate from './EmailTemplates/EditTemplate';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'FLOW.HEADER',
        icon: 'arrow-swap',
      },
      children: [
        {
          path: '',
          redirect: 'flows',
        },
        {
          path: 'ongoing',
          name: 'settings_account_campaigns',
          roles: ['administrator'],
          component: { ...Index },
        },
        {
          path: 'flows',
          name: 'settings_account_campaigns_flow',
          roles: ['administrator'],
          component: { ...FlowList },
        },
        {
          path: 'flows/edit/:flowid',
          name: 'settings_account_campaigns_flow_edit',
          roles: ['administrator'],
          component: { ...FlowEdit },
        },
      ],
    },
    // add express bot
    {
      path: frontendURL('accounts/:accountId/express-bots'),
      component: SettingsContent, // wrapper one
      props: {
        headerTitle: 'FLOW.EXPRESS_BOT.HEADER',
        icon: 'bot',
      },
      children: [
        {
          path: 'list',
          name: 'expressBotList',
          roles: ['administrator'],
          component: { ...ExpressBots },
        },
      ],
    },

    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
        icon: 'sound-source',
      },
      children: [
        {
          path: 'one_off',
          name: 'one_off',
          roles: ['administrator'],
          component: { ...Index },
        },
        {
          path: 'add_campaign',
          name: 'add_campaign',
          roles: ['administrator'],
          component: { ...Index },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.EMAIL_TEMPLATES.HEADER',
        icon: 'sound-source',
        keepAlive: false,
      },
      children: [
        {
          path: 'email_templates',
          name: 'email_templates',
          roles: ['administrator'],
          component: { ...Index },
        },
        {
          path: 'email_templates/add',
          name: 'email_template_add',
          roles: ['administrator'],
          component: { ...AddTemplate },
        },
        {
          path: 'email_templates/edit/:templateId',
          name: 'email_template_edit',
          roles: ['administrator'],
          component: { ...EditTemplate },
        },
      ],
    },
  ],
};

