var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "range_input" }, [
    _c("input", {
      attrs: {
        type: "range",
        id: _vm.id,
        min: _vm.min,
        max: _vm.max,
        disabled: _vm.disabled
      },
      domProps: { value: _vm.value },
      on: { input: _vm.onInput }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }