var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content.type == "outstanding_appointment"
    ? _c(
        "div",
        { staticClass: "card_node_item" },
        [
          _vm.content.title
            ? _c("span", { staticStyle: { color: "black" } }, [
                _vm._v(
                  "\n    " +
                    _vm._s("Outstanding Appointment | " + _vm.content.title) +
                    "\n    "
                )
              ])
            : _c("span", { staticStyle: { color: "grey" } }, [
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm.$t("FLOW.APPOINTMENT_LIST.EDIT_CLICK_OUTSTANDING")
                    ) +
                    "\n    "
                )
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("woot-button", {
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  size: "small",
                  variant: "smooth",
                  "color-scheme": "secondary",
                  icon: "edit"
                },
                on: {
                  click: function($event) {
                    return _vm.openEditModal(_vm.content)
                  }
                }
              }),
              _vm._v(" "),
              _c("woot-button", {
                attrs: {
                  size: "small",
                  variant: "smooth",
                  "color-scheme": "alert",
                  icon: "dismiss"
                },
                on: {
                  click: function($event) {
                    return _vm.deleteItem(_vm.index)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.showEditModal,
                "on-close": _vm.closeEditModal,
                "custom-class": "height"
              },
              on: {
                "update:show": function($event) {
                  _vm.showEditModal = $event
                }
              }
            },
            [
              _c("EditOutstandingAppointment", {
                attrs: {
                  list: this.content,
                  dref: this.dref,
                  botid: this.botid
                },
                on: {
                  "on-close": _vm.closeEditModal,
                  "list-edit-done": _vm.onEditDone
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm.content.type == "appointment_card"
    ? _c(
        "div",
        { staticClass: "card_node_item" },
        [
          _vm.content.reschedule_btn
            ? _c("span", { staticStyle: { color: "black" } }, [
                _vm._v(
                  "\n    " +
                    _vm._s("Appointment Card | " + _vm.content.reschedule_btn) +
                    "\n    "
                )
              ])
            : _c("span", { staticStyle: { color: "grey" } }, [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("FLOW.APPNMT_CARD.EDIT_CLICK_BTN")) +
                    "\n    "
                )
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("woot-button", {
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  size: "small",
                  variant: "smooth",
                  "color-scheme": "secondary",
                  icon: "edit"
                },
                on: {
                  click: function($event) {
                    return _vm.openEditModal(_vm.content)
                  }
                }
              }),
              _vm._v(" "),
              _c("woot-button", {
                attrs: {
                  size: "small",
                  variant: "smooth",
                  "color-scheme": "alert",
                  icon: "dismiss"
                },
                on: {
                  click: function($event) {
                    return _vm.deleteItem(_vm.index)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.showEditModal,
                "on-close": _vm.closeEditModal,
                "custom-class": "height"
              },
              on: {
                "update:show": function($event) {
                  _vm.showEditModal = $event
                }
              }
            },
            [
              _c("edit-card", {
                attrs: {
                  card: this.content,
                  dref: this.dref,
                  botid: this.botid
                },
                on: {
                  "on-close": _vm.closeEditModal,
                  "card-edit-done": _vm.onEditDone
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }