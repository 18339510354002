var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box bg_white" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-right-top",
            icon: "add-circle"
          },
          on: { click: _vm.openAddPopup }
        },
        [_vm._v("\n    " + _vm._s(_vm.buttonText) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.currentPath.includes("one_off") ? _c("campaign") : _vm._e(),
      _vm._v(" "),
      _vm.currentPath.includes("email_templates")
        ? _c("email-template")
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPath.includes("add_campaign")
        ? _c("AddCampaignByStep")
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _c("add-campaign", {
            attrs: { "initial-filter-types": _vm.contactFilterItems },
            on: { "on-close": _vm.hideAddPopup }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }