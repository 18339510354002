var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add_btns" }, [
    _c("div", [
      _c("div", { staticClass: "custom-btn-wrapper" }, [
        _c(
          "button",
          { staticClass: "btn", on: { click: _vm.addOutstandingAppointment } },
          [
            _c("img", {
              attrs: {
                src: require("dashboard/assets/images/flow-new/btn-add.png")
              }
            }),
            _vm._v(
              "\n              " +
                _vm._s(_vm.$t("FLOW.OUTSTANDING_APPOINTMENT")) +
                "\n              "
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "custom-btn-wrapper" }, [
        _c(
          "button",
          { staticClass: "btn", on: { click: _vm.addAppointmentCard } },
          [
            _c("img", {
              attrs: {
                src: require("dashboard/assets/images/flow-new/btn-add.png")
              }
            }),
            _vm._v(
              "\n              " +
                _vm._s(_vm.$t("FLOW.APPOINTMENT_CARD")) +
                "\n            "
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }