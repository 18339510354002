var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("section", [
        _c("div", { staticClass: "settings--content" }, [
          _c("div", { staticClass: "row catalouges-section" }, [
            _c("div", { staticClass: "columns medium-2" }, [
              _c("h6", { staticClass: "catalouges-header" }, [
                _vm._v(_vm._s(_vm.$t("CATALOGUE.HEADER.CATAGORY")))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns medium-10" },
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.categories,
                    "track-by": "id",
                    label: "title",
                    disabled: _vm.isCatalogDisabled(_vm.catalog),
                    multiple: true,
                    "close-on-select": false,
                    "clear-on-select": false,
                    "hide-selected": true,
                    "tag-placeholder": _vm.$t(
                      "CATALOGUE.ADD_EDIT_FORM.CREATE_CTG_PLACEHOLDER"
                    ),
                    taggable: true,
                    placeholder: _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER"
                    ),
                    "selected-label": "",
                    "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                    "deselect-label": _vm.$t(
                      "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                    )
                  },
                  on: { tag: _vm.createCategory, remove: _vm.deleteCategory },
                  model: {
                    value: _vm.selectedCategories,
                    callback: function($$v) {
                      _vm.selectedCategories = $$v
                    },
                    expression: "selectedCategories"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns medium-2" }, [
              _c("h6", { staticClass: "catalouges-header" }, [
                _vm._v(_vm._s(_vm.$t("CATALOGUE.HEADER.CATALOGUES")))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns medium-10" },
              [
                _c(
                  "div",
                  { staticClass: "row item-header toolbar" },
                  [
                    _c("input", {
                      staticClass: "search",
                      attrs: {
                        type: "search",
                        placeholder: _vm.$t(
                          "CATALOGUE.TOOLBAR.SEARCH_PLACEHOLDER"
                        )
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchCatalogueHandler($event)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column small-2",
                        staticStyle: { height: "30px" }
                      },
                      [
                        _c("multiselect", {
                          staticClass: "column small-12",
                          attrs: {
                            options: _vm.categories,
                            "track-by": "id",
                            label: "title",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "hide-selected": true,
                            placeholder: _vm.$t(
                              "CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER"
                            ),
                            "selected-label": "",
                            "select-label": _vm.$t(
                              "FORMS.MULTISELECT.ENTER_TO_SELECT"
                            ),
                            "deselect-label": _vm.$t(
                              "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                            )
                          },
                          on: {
                            select: function($event) {
                              return _vm.applyFilterCategory($event)
                            },
                            remove: function($event) {
                              return _vm.applyFilterRemoveCategory($event)
                            }
                          },
                          model: {
                            value: _vm.filteredCategories,
                            callback: function($$v) {
                              _vm.filteredCategories = $$v
                            },
                            expression: "filteredCategories"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { disabled: _vm.isCatalogDisabled(_vm.catalog) },
                        on: { click: _vm.addCatalogueItems }
                      },
                      [_c("fluent-icon", { attrs: { icon: "add" } })],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  { staticClass: "catalogue_items" },
                  _vm._l(_vm.catalogueItems, function(item) {
                    return _c("div", { key: item.id, staticClass: "item" }, [
                      _c(
                        "div",
                        { staticClass: "action_btns" },
                        [
                          _c("woot-button", {
                            staticClass: "btn-filter",
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              variant: "clear",
                              "color-scheme": "secondary",
                              icon: "edit",
                              size: "small"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openEditCatalogueItem(item)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("woot-button", {
                            staticClass: "btn-filter",
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              variant: "clear",
                              "color-scheme": "secondary",
                              icon: "dismiss",
                              size: "small",
                              isLoading: _vm.uiFlags.isDeleting_catalog_items
                            },
                            on: {
                              click: function($event) {
                                return _vm.openDeletePopup(item)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("SHImage", {
                            staticClass: "card_img",
                            attrs: { src: item.image_link }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "card_info" }, [
                        _c("div", [
                          _c("h6", [_vm._v(_vm._s(item.title))]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(item.description))]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("CATALOGUE.CARD.INVENTORY")) +
                                _vm._s(item.inventory)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "badges" }, [
                            _c("span", { staticClass: "label primary" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.PRICE"
                                  )
                                ) +
                                  ": " +
                                  _vm._s(item.price)
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("multiselect", {
                              staticClass: "column small-12",
                              attrs: {
                                options: _vm.categories,
                                "track-by": "id",
                                label: "title",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "hide-selected": true,
                                placeholder: _vm.$t(
                                  "CATALOGUE.CARD.CATAGORY_PLACEHOLDER"
                                ),
                                "selected-label": "",
                                "select-label": _vm.$t(
                                  "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                ),
                                "deselect-label": _vm.$t(
                                  "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                                )
                              },
                              on: {
                                select: function($event) {
                                  return _vm.assignCategoryToCatalogue(
                                    $event,
                                    item
                                  )
                                },
                                remove: function($event) {
                                  return _vm.removeCategoryfromCatalogue(
                                    $event,
                                    item
                                  )
                                }
                              },
                              model: {
                                value: item.categories,
                                callback: function($$v) {
                                  _vm.$set(item, "categories", $$v)
                                },
                                expression: "item.categories"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "loadMore_btn" },
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          variant: "clear",
                          size: "small",
                          "color-scheme": "primary",
                          "is-loading": _vm.uiFlags.isFetching_catalog_items,
                          "is-disabled": _vm.paginationCompute.isLastPage
                        },
                        on: {
                          click: function($event) {
                            _vm.onPageChange(Number(_vm.meta.currentPage) + 1)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.paginationCompute.btnText) +
                            "\n            "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("woot-delete-modal", {
                  attrs: {
                    show: _vm.showDeleteConfirmationPopup,
                    "on-close": _vm.closeDeletePopup,
                    "on-confirm": _vm.confirmDeletion,
                    title: "Do you want to delete this catalog?",
                    message: "This catalog will be deleted.",
                    "confirm-text": "Yes! Delete now.",
                    "reject-text": "No! Don't delete."
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.showDeleteConfirmationPopup = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAddCatalogue,
            "on-close": _vm.hideAddCatalogue
          },
          on: {
            "update:show": function($event) {
              _vm.showAddCatalogue = $event
            }
          }
        },
        [
          _c("AddCatalogueItem", {
            attrs: {
              createCategory: _vm.createCategory,
              "on-close": _vm.hideAddCatalogue
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showEditCatalogue,
            "on-close": _vm.hideEditCatalogue
          },
          on: {
            "update:show": function($event) {
              _vm.showEditCatalogue = $event
            }
          }
        },
        [
          _c("EditCatalogueItem", {
            attrs: {
              "on-close": _vm.hideEditCatalogue,
              catalogue: _vm.selectedCatelogueToEdit
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }